import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";

import Calendar from "../../assets/calendar.jpg";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ForwardIcon from "@mui/icons-material/Forward";
import LanguageIcon from "@mui/icons-material/Language";
import VerifiedIcon from "@mui/icons-material/Verified";

export default function Features() {
  return (
    <Box
      id="features"
      sx={{
        py: 4,
        background: "#EDE4C7",
      }}
    >
      <Container maxWidth="lg">
        <Stack>
          <Typography variant="h4" sx={{ color: "#000" }}>
            Fonctionnalités
          </Typography>
          <Typography variant="h5" sx={{ color: "#000", pt: 2 }}>
            <ForwardIcon sx={{ position: "relative", top: "4px", mr: 1 }} />
            Gestion des concours équestres
          </Typography>
          <Box sx={{ ml: 3 }}>
            <Stack>
              <Typography
                variant="body"
                sx={{ color: "#000", pt: 2, fontSize: "1rem" }}
              >
                Nous vous proposons est une solution complète pour gérer les
                inscriptions de vos adhérents aux concours équestres auxquels
                vous participez.
                <br />
                Allez visionner nos vidéos de démonstration, elles sont juste en
                dessous !
              </Typography>
              <Grid container spacing={4} sx={{ pt: 2 }}>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <Card
                    sx={{
                      height: "100%",
                      boxShadow: "4px 6px 10px 0px rgb(190, 157, 138)",
                    }}
                    elevation={3}
                  >
                    <CardHeader
                      title="Planifier"
                      subheader="Donner la visibilité"
                      avatar={<EventNoteIcon />}
                      titleTypographyProps={{ variant: "h6" }}
                    />
                    <CardContent>
                      <List sx={{ listStyleType: "square", pl: 4 }}>
                        <ListItem sx={{ display: "list-item", pl: 0 }}>
                          Affiche les concours à venir
                        </ListItem>
                        <ListItem sx={{ display: "list-item", pl: 0 }}>
                          Gestion des types d’épreuves / catégories (*)
                        </ListItem>
                        <ListItem sx={{ display: "list-item", pl: 0 }}>
                          Notification par email
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <Card
                    sx={{
                      height: "100%",
                      boxShadow: "4px 6px 10px 0px rgb(176, 140, 119)",
                    }}
                    elevation={3}
                  >
                    <CardHeader
                      title="S'inscrire"
                      subheader="Tout se fait en ligne"
                      avatar={<LanguageIcon />}
                      titleTypographyProps={{ variant: "h6" }}
                    />
                    <CardContent>
                      <List sx={{ listStyleType: "square", pl: 4 }}>
                        <ListItem sx={{ display: "list-item", pl: 0 }}>
                          Choix des catégories (*)
                        </ListItem>
                        <ListItem sx={{ display: "list-item", pl: 0 }}>
                          Choix du nombre de tours
                        </ListItem>
                        <ListItem sx={{ display: "list-item", pl: 0 }}>
                          Paiement en ligne possible
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <Card
                    sx={{
                      height: "100%",
                      boxShadow: "4px 6px 10px 0px rgb(161, 119, 91)",
                    }}
                    elevation={3}
                  >
                    <CardHeader
                      title="Valider"
                      subheader="Gérer les inscriptions"
                      avatar={<VerifiedIcon />}
                      titleTypographyProps={{ variant: "h6" }}
                    />
                    <CardContent>
                      <List sx={{ listStyleType: "square", pl: 4 }}>
                        <ListItem sx={{ display: "list-item", pl: 0 }}>
                          Liste des inscriptions
                        </ListItem>
                        <ListItem sx={{ display: "list-item", pl: 0 }}>
                          Gestion des états (en attente, validé, payé, etc.)
                        </ListItem>
                        <ListItem sx={{ display: "list-item", pl: 0 }}>
                          Déclenchement de la facturation
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3}>
                  <Card
                    sx={{
                      height: "100%",
                      boxShadow: "4px 6px 10px 0px rgb(137, 103, 85)",
                    }}
                    elevation={3}
                  >
                    <CardHeader
                      title="Personnaliser"
                      subheader="Un site à votre image"
                      avatar={<DisplaySettingsIcon />}
                      titleTypographyProps={{ variant: "h6" }}
                    />
                    <CardContent>
                      <List sx={{ listStyleType: "square", pl: 4 }}>
                        <ListItem sx={{ display: "list-item", pl: 0 }}>
                          Vos visuels et logo
                        </ListItem>
                        <ListItem sx={{ display: "list-item", pl: 0 }}>
                          Gestion des types de concours / catégories / tarifs
                        </ListItem>
                        <ListItem sx={{ display: "list-item", pl: 0 }}>
                          Une URL pour chaque club
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Typography
                variant="body"
                sx={{ color: "#000", pt: 3, fontSize: "1rem" }}
              >
                Nous ne sommes pas (encore) connectés à la FFE, vous devez donc
                reporter les inscriptions sur leur site. C'est le seul travail
                supplémentaire que vous aurez à faire.
              </Typography>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ pt: 3 }}>
                <Card
                  sx={{
                    height: "100%",
                    backgroundColor: "#f1ead6",
                    p: 2,
                    color: "#000",
                    fontSize: "0.7rem",
                    fontStyle: "italic",
                  }}
                  elevation={3}
                >
                  (*) Nous gérons toutes les disciplines présentes sur le site
                  de la FFE : Attelage, CCE, Courses à poney, CSO, Derb
                  Eventing, Derby Cross, Dressage, Endurance, Endurance
                  Attelage, Equifeel, Équifun, Equitation Western, Hunter,
                  Moutain Trail, Pony-Games, Ride and Bike, Ride and run, Tir à
                  l'arc à cheval, Trec Attelage, PMG International, Equitations
                  de Travail et Tradition, Para Attelage, Para Dressage, Travail
                  à pied, TREC, Tri Ride, et Voltige.
                  <br />
                  (**) Si votre discipline n'est pas dans la liste,
                  contactez-nous, nous serons ravis de l'ajouter.
                </Card>
              </Grid>
            </Stack>
          </Box>
          <Typography variant="h5" sx={{ color: "#000", pt: 2, mt: 2 }}>
            <ForwardIcon sx={{ position: "relative", top: "4px", mr: 1 }} />
            Suivez et organisez facilement les activités de votre cheval
          </Typography>
          <Box sx={{ ml: 3 }}>
            <Grid container spacing={4} sx={{ pt: 2 }}>
              <Grid
                item
                xs={12}
                sm={5}
                md={4}
                lg={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Calendar}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "200px",
                    width: "auto",
                    height: "auto",
                    borderRadius: "4px",
                    boxShadow: "4px 6px 10px 0px rgb(137, 103, 85)",
                  }}
                  alt="calendar"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={8} lg={9}>
                <Typography
                  variant="body"
                  sx={{ color: "#000", fontSize: "1rem" }}
                >
                  Découvrez notre nouvelle fonctionnalité dédiée aux
                  propriétaires et cavaliers en pension ! <br />
                  Gérez l’agenda de votre cheval en renseignant les cours
                  réguliers, les activités ponctuelles et les rendez-vous
                  importants (dentiste, vaccins, etc.). <br />
                  Suivez son évolution grâce aux commentaires et aux notes
                  laissés après chaque séance. <br />
                  <br />
                  Pour les cavaliers en demi-pension, cette fonctionnalité
                  facilite la coordination en évitant de répéter les mêmes
                  exercices. <br />
                  <br />
                  Un outil simple et complet pour assurer le bien-être et la
                  progression de votre cheval au quotidien.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
