import { Box, Container, Grid, Typography } from "@mui/material";

import Fee from "../../assets/fee.jpg";

export default function Prices() {
  return (
    <Box id="price" sx={{ backgroundColor: "#fff", py: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} sx={{ pt: 2 }}>
          <Grid item xs={12} sm={12} md={7} lg={9}>
            <Typography variant="h4" sx={{ color: "#000" }}>
              Tarifs simples et transparents
            </Typography>

            <Typography sx={{ fontSize: "1rem", mt: 3 }}>
              ✔️ Aucun frais d’inscription
              <br />
              ✔️ Aucun abonnement
              <br />
              ✔️ Aucun frais caché
              <br />
              <br />
              Les frais sont uniquement liés aux concours que vous organisez.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={3}
            sx={{ textAlign: { md: "right", sm: "center", xs: "center" } }}
          >
            <img
              src={Fee}
              alt="fee"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                width: "auto",
                height: "auto",
                borderRadius: "4px",
                boxShadow: "4px 6px 10px 0px rgb(137, 103, 85)",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
              Deux options au choix pour les inscriptions aux concours des
              cavaliers :
            </Typography>
            <Typography sx={{ fontSize: "1rem" }}>
              <ol style={{ marginTop: "6px" }}>
                <li>
                  <b>Paiement en ligne pour les cavaliers</b>
                  <ul>
                    <li>1% de frais de service, facturés au cavalier.</li>
                    <li>
                      1,5% + 0,25€ de frais de traitement, facturés au club*
                    </li>
                  </ul>
                </li>
                <li>
                  <b>
                    Paiement hors ligne pour les cavaliers, facturation directe
                    par le club
                  </b>
                  <ul>
                    <li>2,25€ par concours, facturés au club.</li>
                  </ul>
                </li>
              </ol>
              <Typography sx={{ fontSize: "1rem", pl: 3 }} color="#302818">
                🎁 Les 3 premiers concours sont gratuits pour tester la
                plateforme !
              </Typography>
            </Typography>
            <Typography
              sx={{ fontSize: "1.1rem", pt: 2, fontWeight: "bold", mt: 2 }}
            >
              Fonctionnalité incluse : Gestion de l’agenda des chevaux
            </Typography>
            <Typography sx={{ fontSize: "1rem", marginTop: "6px" }}>
              Profitez gratuitement de notre outil de gestion d’agenda pour
              planifier les activités et soins de vos chevaux. Suivi des
              séances, commentaires et coordination simplifiée pour les
              cavaliers en demi-pension. <b>Sans frais et sans engagement.</b>
            </Typography>
            <Typography
              sx={{ fontSize: "1.1rem", pt: 2, fontWeight: "bold", mt: 2 }}
            >
              Abonnement
            </Typography>
            <Typography sx={{ fontSize: "1rem" }}>
              <ul style={{ marginTop: "6px" }}>
                <li>0€/mois</li>
                <li>
                  Création gratuite d’un sous-domaine : <i>monclub</i>
                  .concours-equestre.fr
                </li>
                <li>
                  Utilisation de votre propre nom de domaine : 15€ (frais
                  uniques de mise en place)
                </li>
              </ul>
            </Typography>
            <Typography
              sx={{
                fontSize: ".8rem",
                mt: 2,
              }}
            >
              * Ces frais sont liés à l'utilisation de la solution de paiement
              en ligne Stripe. Ils peuvent varier en fonction du type de carte
              bancaire utilisée.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
